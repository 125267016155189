import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import SEO from "../components/seo"

// import { GatsbyImage } from "gatsby-plugin-image";
const spacingH = "clamp(1.2rem, 4vw, 6rem)"
const wpPage = ({
  data: {
    wpPage: { title, content, id, featuredImage },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} />
      <div
        className={`post-${id}`}
        css={css`
          padding: 12rem ${spacingH};
        `}
      >
        <h1>{title}</h1>
        {featuredImage && (
          <img
            id="test"
            className="featured-image"
            alt="test"
            src={featuredImage.node.sourceUrl}
          ></img>
        )}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Layout>
  )
}

export default wpPage

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          srcSet
          sourceUrl
        }
      }
    }
  }
`
